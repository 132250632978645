import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import '../i18n';
import Logo from "../images/charity-logo.svg";
import "../header/header.css";
import "../main/main.css";
import { ArrowRight02Icon, Cancel01Icon } from 'hugeicons-react';
import { LanguageCircleIcon } from 'hugeicons-react';
import { ArrowDown01Icon } from 'hugeicons-react';
import { Navigate } from "react-router-dom";

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const PageHeader = ({ initialDarkMode }) => {
  const { t, i18n } = useTranslation();
  const [isLangOpen, setIsLangOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(initialDarkMode);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const changeLanguage = (lng) => {
    localStorage.setItem('selectedLanguage', lng);
    setSelectedLanguage(lng);
    i18n.changeLanguage(lng);
    setIsLangOpen(false);
  };

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('selectedLanguage') || 'en');
  }, [i18n]);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      setIsScrolled(true);
      setIsDarkMode(false);
    } else {
      setIsScrolled(false);
      setIsDarkMode(initialDarkMode);
    }
  };


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [initialDarkMode]);

  const handleLinkClick = (sectionId) => {
    closeMenu();

    if (sectionId === 'programs' || sectionId === 'contact') {
      navigate('/' + sectionId);
    } else {
      if (window.location.pathname !== '/') {
        navigate('/#' + sectionId);
      } else {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        } else {
          window.location.hash = sectionId;
        }
      }
    }
  };

  return (
    <header className={`page-header ${isScrolled || isMenuOpen ? 'scrolled' : ''} ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <nav>
        <Link className="branding">
          <img src={Logo} alt="Logo" />
        </Link>

        <div className={`nav-menu ${isMenuOpen ? 'responsive' : ''}`}>
          <div className="cancel" onClick={closeMenu}>
            <Cancel01Icon size={24} color={"#000000"} variant={"stroke"} />
          </div>
          <div className="links">
            <Link className="link" to={'/'} onClick={() => handleLinkClick('home')}>{t('navigation.home')}</Link>
            <Link to={'/#about-us'} onClick={() => handleLinkClick('about-us')}>{t('navigation.about')}</Link>
            <Link to={'/programs'} onClick={() => handleLinkClick('programs')}>{t('navigation.programs')}</Link>
            <Link to={'/#volunteer'} onClick={() => handleLinkClick('volunteer')}>{t("navigation.getinvolved")}</Link>
            <Link to={'/#our-shops'} onClick={() => handleLinkClick('our-shops')}>{t("navigation.shop")}</Link>
            <Link to={'/#our-events'} onClick={() => handleLinkClick('our-events')}>{t("navigation.events")}</Link>
            <Link to={'/contact'} onClick={() => handleLinkClick('contact')}>{t("navigation.contact")}</Link>
            <div className="responsive-lgn">
              <div className="language-selector">
                <button onClick={() => setIsLangOpen(!isLangOpen)} className="language-button">
                  <span className="language-icon">
                    <LanguageCircleIcon size={24} variant={"stroke"} />
                  </span>
                  <span>{selectedLanguage === 'en' ? 'English' : 'Français'}</span>
                  <span className="arrow-icon"><ArrowDown01Icon /></span>
                </button>
                {isLangOpen && (
                  <div className="language-dropdown">
                    <button onClick={() => changeLanguage('en')}>English</button>
                    <button onClick={() => changeLanguage('fr')}>Français</button>
                  </div>
                )}
              </div>
              <div>
                <button className="donate-btn" onClick={() => navigate('/programs')}>
                  {t("navigation.donate")}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="donate-changelgn">
          <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
            {isDarkMode && !isScrolled ? <img src="/cardIcons/menu-icon.svg" alt="menu icon" /> : <img src="/cardIcons/menu-dark.svg" alt="menu icon" />}
          </div>
          <div className="hide-respon">
            <div className="language-selector">
              <button onClick={() => setIsLangOpen(!isLangOpen)} className={`language-button ${isScrolled || isMenuOpen ? 'scrolled' : ''} ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
                <span className="language-icon">
                  <LanguageCircleIcon size={24} color={"#FFFFFF"} variant={"stroke"} className={`react-icon ${isScrolled ? 'scrolled' : ''} ${isDarkMode ? 'dark-mode' : 'light-mode'}`} />
                </span>
                <span>{selectedLanguage === 'en' ? 'English' : 'Français'}</span>
                <span className="arrow-icon">
                  <ArrowDown01Icon size={24} color={"#000000"} variant={"stroke"} className={`react-icon ${isScrolled ? 'scrolled' : ''} ${isDarkMode ? 'dark-mode' : 'light-mode'}`} />
                </span>
              </button>
              {isLangOpen && (
                <div className="language-dropdown">
                  <button onClick={() => changeLanguage('en')}>English</button>
                  <button onClick={() => changeLanguage('fr')}>Français</button>
                </div>
              )}
            </div>
            <div>
              <button className="donate-nav" onClick={() => navigate('/programs')}>
                {t("navigation.donate")}
              </button>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};



export default PageHeader;