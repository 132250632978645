import React, { useEffect, useState } from "react";
import PageHeader from "./header";
import { useTranslation } from "react-i18next";
import "../i18n";
import SectionTitle from "./sectionTitle";
import EventsCard from "./eventsCard";
import FaqsCard from "./faqsCard";
import ContactCard from "./contactCard";
import ProductCard from "./products/productCard";
import Footer from "./footer";
import StoriesCard from "./storiesCard";
import ProgramCard from "./programCard";
import { englishFaqs } from "../locales/english_faq";
import { frenchFaqs } from "../locales/french_faq";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SlicedText from "./slicedText";
import { Calendar03Icon, Location01Icon } from "hugeicons-react";
import { formatDate } from "../constants";
import EventRegistrationForm from "./eventRegistration";

const Home = () => {
  const [openFaqIndex, setOpenFaqIndex] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const { t, i18n } = useTranslation();
  const [eventButtonText, setEventButtonText] = useState(t("homePage.eventsSection.buttonText"));
  const [programsButtonText, setProgramsButtonText] = useState(t("homePage.ourProgramsSection.buttonText"));
  // events
  const [events, setEvents] = useState([]);
  const [englishEvents, setEnglishEvents] = useState([]);
  const [frenchEvents, setFrenchEvents] = useState([]);
  const [gettingEvents, setGettingEvents] = useState(true)
  // programs
  const [programs, setPrograms] = useState([])
  const [englishPrograms, setEnglishPrograms] = useState([])
  const [frenchPrograms, setFrenchPrograms] = useState([])
  const [gettingPrograms, setGettingPrograms] = useState([])
  const [currentLanguage, setCurrentLanguage] = useState("")

  const navigate = useNavigate()

  const handleViewDetails = (program) => {
    navigate(`/programs/${program.id}`, { state: program });
  }

  useEffect(() => {
    const updateText = () => {
      if (window.innerWidth <= 900) {
        setEventButtonText(t("homePage.eventsSection.buttonTextSmall"));
        setProgramsButtonText(t("homePage.ourProgramsSection.buttonTextSmall"));
      } else {
        setEventButtonText(t("homePage.eventsSection.buttonText"));
        setProgramsButtonText(t("homePage.ourProgramsSection.buttonText"));
      }
    };

    updateText();
    window.addEventListener("resize", updateText);

    return () => window.removeEventListener("resize", updateText);
  }, []);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("selectedLanguage") || "en");
  }, [i18n]);

  useEffect(() => {
    if (i18n.language === "en") {
      setFaqs(englishFaqs);
    } else if (i18n.language === "fr") {
      setFaqs(frenchFaqs);
    }
  }, [i18n.language]);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const loadFaqTranslations = () => {
    const currentLang = i18n.language;
    if (currentLang === "en") {
      i18n.addResources("en", "faq", englishFaqs);
    } else if (currentLang === "fr") {
      i18n.addResources("fr", "faq", frenchFaqs);
    }
  };

  const api = process.env.REACT_APP_API_URL

  useEffect(() => {
    // get current language
    if (localStorage.getItem("selectedLanguage") === 'en') {
      setEvents(englishEvents); setPrograms(englishPrograms)
    } else {
      setEvents(frenchEvents); setPrograms(frenchPrograms)
    }

  })

  useEffect(() => {
    const fetchEvents = async () => {
      setGettingEvents(true);
      const french = []
      const english = []
      try {
        const response = await axios.get(`${api}/events/area/canada/`);
        const events = response.data.event
        console.log('evetns: ', events)

        events.forEach((event) => {
          // console.log(event.content)
          event.content.forEach((content) => {
            const event_obj = {
              id: event.id,
              title: content.title,
              slug: event.slug,
              description: content.description,
              start_date: event.start_date,
              location: event.location,
              image: event.image,
              // slug: event.slug,
              content: content,
            }
            console.log(event_obj)
            if (content.lang === 'French') {
              french.push({ ...event_obj, content })
            } else if (content.lang === 'English') {
              english.push({ ...event_obj, content })
            }
          })
        })
        setFrenchEvents(french)
        setEnglishEvents(english)
        setGettingEvents(false)
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(`${api}/programs/area/canada/`);
        console.log('Getting programs...');
        if (response.status === 200) {
          setFrenchPrograms(response.data.french)
          setEnglishPrograms(response.data.english)
          setGettingPrograms(false)
        }
      } catch (error) {
        setGettingPrograms(false)
        console.log('Error fetching programs:', error);
      }
    }
    fetchPrograms()
  }, [])

  const programsData = [
    {
      id: 1,
      title: t('homePage.ourProgramsSection.programTitle'),
      description: t('homePage.ourProgramsSection.programDescription'),
      progress: '24%',
      link: 'https://www.charityhelpinghands.org/sponsor-a-program/',
      image: '/images/big-image.webp',
      details: t('homePage.ourProgramsSection.programDetails')
    },
    {
      id: 2,
      title: t('homePage.ourProgramsSection.programTwoTitle'),
      description: t('homePage.ourProgramsSection.programTwoDescription'),
      progress: '10%',
      link: 'https://www.charityhelpinghands.org/young-parent-program/',
      image: '/images/senior-citizens.webp',
      details: t('homePage.ourProgramsSection.programTwoDetails')
    },
    {
      id: 3,
      title: t('homePage.ourProgramsSection.programThreeTitle'),
      description: t('homePage.ourProgramsSection.programThreeDescription'),
      progress: '20%',
      link: 'https://www.charityhelpinghands.org/young-parent-program/',
      image: '/images/mother.webp',
      details: t('homePage.ourProgramsSection.programThreeDetails')
    },

  ]



  return (
    <div className="charity-home">
      <div className="hero">
        <PageHeader initialDarkMode={true} />
        <div className="hero-section">
          <div className="container">
            <div className="hero-text">
              <div className="heanding1">{t("homePage.heroSection.title")}</div>
              <div className="paragraph">
                {t("homePage.heroSection.introduction")}
              </div>
              {/* <div className="button">
                {t("homePage.heroSection.buttontext")}
              </div> */}
            </div>
            <div className="inquiries-info">
              {/* <div className="container"> */}
              <div className="general-inquirie">
                <div className="icon-inquirie">
                  <img src="/cardIcons/heart.svg" alt="heart icon" />
                </div>
                <div className="heading2">
                  {t("homePage.aboutUsSection.icontext")}
                </div>
              </div>
              <div className="divider"></div>
              <div className="info-inquirie">
                <div className="paragraph">
                  {t("homePage.aboutUsSection.call")}: +1-819-679-1001
                </div>
                <div className="paragraph">
                  {t("homePage.aboutUsSection.email")}:{" "}
                  {t("homePage.aboutUsSection.emailAddress")}
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="body-section container">
        <div className="about-us" id="about-us">
          <div className="container">
            <div className="about-us-images">
              <div className="purple-background"></div>
              <div className="big-image">
                <div className="img"></div>
              </div>
              <div className="small-image-touche">
                <div className="small-image">
                  <div className="img"></div>
                </div>
                <div className="touche">
                  <div className="icon">
                    <img src="/cardIcons/heart-icon.svg" alt="" />
                  </div>
                  <div className="text-touche">
                    <div className="heading1">200+</div>
                    <div className="heading3">
                      {t("homePage.aboutUsSection.pictureicontext")}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="about-us-text">
              <SectionTitle
                title={t("homePage.aboutUsSection.title")}
                subTitle={t("homePage.aboutUsSection.subtitlebold")}
                subTitleSpan={t("homePage.aboutUsSection.subtitle")}
              />
              <div className="paragraph2">
                {t("homePage.aboutUsSection.description")}
              </div>
              {/* <div className="button">
                {t("homePage.aboutUsSection.buttontext")}{" "}
                <img src="/cardIcons/right-arrow.svg" alt="right arrow" />
              </div> */}

              {/* <div className="impact-back-img">
                <img src="/cardIcons/back-image.svg" alt="" />
              </div> */}
            </div>
          </div>
        </div>

        <div className="our-values-container">
          <div className="our-values " id="our-values">
            <div className="heading1">
              {t("homePage.ourValuesSection.Ourvalues")}
            </div>
            <div className="heading3">
              {t("homePage.ourValuesSection.description")}
            </div>
          </div>
        </div>

        <div className="our-goals" id="our-goals">
          <div className="container">
            <div className="our-goal-image">
              <img src="/images/home/charity-helping-hands-goals.jpg" alt="children" />
            </div>
            <div className="our-goal-text">
              <div className="goal">{t("homePage.ourGoalsSection.title")}</div>
              <div className="heading3">
                {t("homePage.ourGoalsSection.descriptionbold")}
              </div>
              <div className="paragraph">
                {t("homePage.ourGoalsSection.decription")}
              </div>
              <div className="goals">
                <div className="heading4">
                  {t("homePage.ourObjectivesSection.title")}
                </div>
                <div className="paragraph">
                  {t("homePage.ourObjectivesSection.description")}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="our-programs" id="our-programs">
        <div className="container">
          <div className="headind4">
            {t("homePage.ourProgramsSection.title")}
          </div>
          <div className="our-program-title">
            <div className="heading1">
              {t("homePage.ourProgramsSection.subtitlebold")}{" "}
              <span>{t("homePage.ourProgramsSection.subtitle")}</span>
            </div>
            <div className="button" onClick={() => navigate('/programs/')}>
              {programsButtonText}{" "}
              <img src="/cardIcons/arrow.svg" alt="arrow" />
            </div>
          </div>

          <div className="programs">
            {
              gettingPrograms
                ? 'Getting Programs ..'
                :
                programs.slice(3).map((program, index) => (
                  <ProgramCard
                    key={index}
                    title={program.name}
                    description={<SlicedText text={program.description} length={150} />}
                    progress={program.progress}
                    onViewDetails={() => handleViewDetails(program)}
                    link={program.link}
                    image={program.image}
                  />
                ))

            }

          </div>
        </div>
      </div>

      <div className="body-section container">
        <div className="our-impact" id="our-impact">
          <div className="container">
            <div className="impacts">
              <div className="impact-text">
                <SectionTitle
                  title={t("homePage.impactSection.title")}
                  subTitle={t("homePage.impactSection.subtitlebold")}
                  subTitleSpan={t("homePage.impactSection.subtitle")}
                />
                <div className="paragraph">
                  {t("homePage.impactSection.description")}
                </div>
                <div className="impact-text-value">
                  <div className="value">
                    <div className="icon">
                      <img src="/cardIcons/workshop.svg" alt="workshop icon" />
                    </div>
                    <div className="value-text-number">
                      <div className="numbers">40+</div>
                      <div className="paragraph">
                        {t("homePage.impactSection.icononetext")}
                      </div>
                    </div>
                  </div>
                  <div className="value">
                    <div className="icon">
                      <img
                        src="/cardIcons/volunteer.svg"
                        alt="volunteer icon"
                      />
                    </div>
                    <div className="value-text-number">
                      <div className="numbers">37+</div>
                      <div className="paragraph">
                        {t("homePage.impactSection.iconwotext")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="impact-image">
                <div className="impact-small-img">
                  <img src="/images/home/helping.jpg" alt="mother and daughter" />
                </div>
                <div className="impact-big-img">
                  <img src="/images/home/charity helping hands giving donations.jpg" alt="students" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="our-events" id="our-events">
          <div className="container">
            <div className="headind4">{t("homePage.eventsSection.events")}</div>
            <div className="our-program-title">
              <div className="heading1">
                {t("homePage.eventsSection.subtitlebold")}{" "}
                <span>{t("homePage.eventsSection.subtitle")}</span>
              </div>

            </div>

            {/* <div className="events-image-text">
              <div className="first-row">
                <div className="event-image">
                  <img
                    src="/images/home/events.jpg"
                    alt="senior citizens"
                  />
                </div>

              </div>
              <div className="second-row">
                {
                  gettingEvents
                    ? 'Getting events'
                    : events && events.slice(0, 3).map((event) => {
                      const { date, month } = formatDate(event.start_date);
                      return (
                        <EventsCard
                          key={event.id}
                          path={`/event/${event.slug}/details/`}
                          date={date}
                          month={month}
                          title={event.title}
                          detail={event.description}
                          background="/images/default-event-background.jpg"
                        />
                      );
                    })
                }
              </div>
            </div> */}

            <EventsSection events={events} />
          </div>
        </div>
      </div>

      {/* <div className="our-stories" id="our-stories">
        <div className="container">
          <div className="stories-title">
            <div className="story-heading1">
              {t("homePage.storiesSection.title")}
            </div>
            <div className="paragraph">
              {t("homePage.storiesSection.subtitlebold")}{" "}
              <span>{t("homePage.storiesSection.subtitle")}</span>
            </div>
          </div>

          <div className="stories">
            <StoriesCard
              role="student"
              story="Dignissim in quis ut nunc integer. Malesuada enim non adipiscing lorem arcu tellus quam vitae duis. In et quis scelerisque quisque nec neque dolor sed sed. Facilisi dignissim aenean senectus nibh sit massa cras tempus. Elementum mattis in in nec felis imperdiet mauris massa. Varius sed semper arcu morbi hac quam pretium."
              name="Maisy Harper"
              imgSrc="/images/student.webp"
            />
            <StoriesCard
              role="student"
              story="Dignissim in quis ut nunc integer. Malesuada enim non adipiscing lorem arcu tellus quam vitae duis. In et quis scelerisque quisque nec neque dolor sed sed. Facilisi dignissim aenean senectus nibh sit massa cras tempus. Elementum mattis in in nec felis imperdiet mauris massa. Varius sed semper arcu morbi hac quam pretium."
              name="Felix Stokes"
              imgSrc="/images/felix.webp"
            />
          </div>

          <div className="story-button">
            {t("homePage.storiesSection.buttonText")}{" "}
            <img src="/cardIcons/right-arrow.svg" alt="right arrow" />
          </div>
        </div>
      </div> */}
      <div className="body-section container">
        <div className="shops" id="our-shops">
          <div className="container">
            <SectionTitle
              title={t("homePage.shopSection.title")}
              subTitle={t("homePage.shopSection.subtitlebold")}
              subTitleSpan={t("homePage.shopSection.subtitle")}
            />
            <div className="our-program-title">
              <div className="heading1">
                {t("homePage.shopSection.description")}
              </div>
              {/* <div className="button">
                {t("homePage.shopSection.buttonText")}{" "}
                <img src="/cardIcons/arrow.svg" alt="arrow" />{" "}
              </div> */}
            </div>

            <div className="shops-images-texts">
              {/* <ProductCard
                name="Calabash/Calebasse"
                price="25.00"
                image="/images/calabash.webp"
              /> */}

              <ProductCard
                link='https://www.charityhelpinghands.org/product/crunchy-chin-chin/'
                name="Crunchy Chin Chin"
                price="5.99 – $38.99"
                image="/images/chin-chin.webp"
              />

              <ProductCard
                link="https://www.charityhelpinghands.org/product/calabash-calebasse/"
                name="Calabash/Calebasse"
                price="25.00"
                image="/images/calabash.webp"
              />
              <ProductCard
                link="https://www.charityhelpinghands.org/product/honey-melon-juice/"
                name="Honey Melon Juice"
                price="4.99"
                image="/images/honey-melon.png"
                specialStyle
              />

              <ProductCard
                link='https://www.charityhelpinghands.org/product/christmas-gift-bundles/'
                name="Christmas Gift Bundles"
                price="15.00 - $40.00"
                image="/images/package.webp"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="faqs" id="faqs">
        <div className="container">
          <div className="headind4">
            {t("homePage.frequentlyAskedQuestonsSection.title")}
          </div>
          <div className="our-program-title">
            <div className="heading1">
              {t("homePage.frequentlyAskedQuestonsSection.subtitlebold")}{" "}
              <span>
                {t("homePage.frequentlyAskedQuestonsSection.subtitle")}
              </span>
            </div>
            {/* <div className="button">
              {t("homePage.frequentlyAskedQuestonsSection.buttonText")}{" "}
              <img src="/cardIcons/arrow.svg" alt="right arrow" />
            </div> */}
          </div>

          <div className="faq-questions">
            {faqs.map((faq, index) => (
              <FaqsCard
                key={index}
                number={(index + 1).toString()}
                question={faq.question}
                answer={faq.answer}
                isOpen={openFaqIndex === index}
                onToggle={() => {
                  if (openFaqIndex === index) {
                    setOpenFaqIndex(null);
                  } else {
                    setOpenFaqIndex(index);
                  }
                }}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="call-of-action" id="volunteer">
        <div className="heading1">{t("homePage.volunteerSection.title")}</div>
        <div className="paragraph">
          {t("homePage.volunteerSection.description")}
        </div>
        <div className="button" >
          <Link to='https://www.charityhelpinghands.org/get-involved/' target="__blank" style={{ textDecoration: 'none', color: '#9B51E0' }}>{t("homePage.volunteerSection.buttontext")}</Link>
        </div>
      </div>

      <div className="body-section container">
        <div className="contact-us">
          <div className="container">
            <div className="contact-us-text">
              <SectionTitle
                title={t("homePage.contausSection.title")}
                subTitle={t("homePage.contausSection.subtitlebold")}
                subTitleSpan={t("homePage.contausSection.subtitle")}
              />
              <div className="our-program-title">
                <div className="paragraph">
                  {t("homePage.contausSection.description")}
                </div>
              </div>
            </div>

            <div className="contact-forms">
              <ContactCard />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;

const EventsSection = ({ events }) => {
  const { t, i18n } = useTranslation()
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [redirectToSpecialForm, setRedirectToSpecialForm] = useState(false);

  const handleSelectEvent = (event) => {
    if (event.title === "Back To School BBQ") {
      setRedirectToSpecialForm(true);
    } else {
      setShowRegistrationForm(true);
      setSelectedEvent(event);
    }
  };
  const hideRegistrationForm = () => {
    setShowRegistrationForm(false)
    setSelectedEvent({})
  };
  if (redirectToSpecialForm) {
    return <useNavigate to="/special-event-form" />;
  };


  return (

    <div className="events-image-text">
      {
        showRegistrationForm && (
          <div className="registration-form-popup">
            <EventRegistrationForm event={selectedEvent} eventContent={events} toggleRegistrationForm={hideRegistrationForm} />
          </div>
        )
      }
      <div className="events">
        {events && events.slice(0, 3).map((event) => (
          <div className="event">
            <img src={event.image ? `${event.image}` : '/images/conference.jpg'} alt="" />
            <div className="event-content">
              <h3>{event.title}</h3>
              <p><SlicedText text={event.description} length={100} /></p>
              <div className="date-location">
                <div className="date">
                  <Calendar03Icon />
                  <div className="text-content">
                    <small>Happening on: </small>
                    <div className="date-number">{formatDate(event.start_date)}</div>
                  </div>
                </div>
                <div className="location">
                  <Location01Icon />
                  <div className="text-content">
                    <small>Location: </small>
                    <div className="location-text">{event.location}</div>
                  </div>
                </div>
              </div>
              <div className="buttons event-buttons">
                {
                  event.id === 7
                    ? <Link to={`/back-to-school-bbq-event/`} className="button primary-button">{t("eventDetailsPage.buttonText")}</Link>

                    : event.id === 8
                      ? <Link target="blank" to={'https://form.jotform.com/242671666756267'} className="button primary-button" >{t("eventDetailsPage.buttonText")}</Link>
                      : <button onClick={() => handleSelectEvent(event)} type="button" className="primary-button">{t("eventDetailsPage.buttonText")}</button>

                }
                <Link to={`/event/${event.slug}/details/`} className="button secondary-button">{t("eventDetailsPage.viewMoreDetails")}</Link>
              </div>
            </div>

          </div>
        ))
        }
      </div>
    </div>
  )
}