import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./i18n";
import HomePage from "./pages/web/homePage";
import AboutUsPage from "./pages/web/aboutUsPage";
import ProgramsPage from "./pages/web/programsPage";
import ContactPage from "./pages/web/contactLayout";
import DetailPageLayout from "./pages/web/program_detail_layout";
import LandingPage from "./componets/landingPage";
import { ScrollToTop } from "./componets/header";
import ProgramDetails from "./pages/web/programDetails";
import EventRegistrationForm from "./componets/eventRegistration";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EventDetails from "./pages/web/events/eventDetails";
import SpecialEventForm from "./pages/web/specialEventForm";

function App() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <ToastContainer />
      <Router>
        <ScrollToTop />
        <Routes>

          <Route path="/" element={<HomePage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/programs/" element={<ProgramsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/details/" element={<DetailPageLayout />} />
          <Route path="/programs/:id/" element={<ProgramDetails />} />
          {/* <Route path='/' element={<LandingPage />} /> */}
          <Route path='/event/:eventSlug/details/' element={<EventDetails />} />

          {/* Special route for Back To School BBQ EVENT*/}
          <Route path="/back-to-school-bbq-event/" element={<SpecialEventForm />} />
        </Routes>
      </Router>

    </>

  );
}

export default App;
